import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import { byKey, getMatchLocation, getPrizeDrawLocation } from "../../helpers/utils";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import FilteredWinners from "./FilteredWinners";
import SearchKeyword from "../../Components/SearchKeyword";
import dayjs from "dayjs";

function Winners() {
  const storeKeyword = useStores().keywordStore;

  const user = useStores().authStore.user;
  const [keyword, setKeyword] = useState(storeKeyword.keyword);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (storeKeyword.keyword.word) {
      setKeyword(storeKeyword.keyword);
    } else {
      navigate("/winners");
    }
  }, [storeKeyword.keyword, navigate]);

  const { isLoading: isLoadingGrandPrizeDraw, data: grandPrizeDraws } = useQuery(
    ["getGrandPrizeDraws", {}],
    async () => {
      const res: MainDbReturnTypes.GrandPrizeDraws = await campaignClient.call(
        "getGrandPrizeDraws",
        {}
      );
      const tag = `keyword:${keyword.keywordKey}`;
      return res.grandPrizeDraws.filter((x: any) => x.parameters.allTags?.includes(tag));
    },
    { enabled: keyword.keywordKey ? true : false }
  );

  const { isLoading: isLoadingPrizeWinners, data: prizeWinners } = useQuery(
    ["getWinners1", { keyword }],
    async () => {
      // const grandPrizeDraws = await getGrandPrizeDrawsForKeyword(
      //   keyword.keywordKey
      // );

      let winners: MainDbReturnTypes.PrizeWinner[] = [];

      if (!grandPrizeDraws) {
        return;
      }

      for (let draw of grandPrizeDraws) {
        const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
          grandPrizeDrawKey: draw.grandPrizeDrawKey,
        });

        winners.push(...res.prizeWinners);

        for (let p of res.prizeWinners) {
          const guests: MainDbReturnTypes.Participants = await campaignClient.call(
            "getParticipants",
            { allTags: [`guest-of:${p.participant.sessionKey}`] }
          );
          if (guests.participants.length) {
            for (let w of guests.participants) {
              const guests: MainDbReturnTypes.PrizeWinners = await campaignClient.call(
                "getPrizeWinners",
                { sessionKey: w.sessionKey }
              );
              if (guests.prizeWinners.length) {
                const filter_current = guests.prizeWinners.filter(g => g.prizePoolKey === draw.prizePoolKey)
                for (let p of filter_current) {
                  winners.push(p);

                }

              }
            }
          }
        }
      }

      return winners;
    },
    { enabled: grandPrizeDraws && grandPrizeDraws.length > 0 ? true : false }
  );
  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});
    const prizes = byKey(res.prizes, (x) => x.prizeKey);
    return prizes;
  });
  const { isLoading: isLoadingLocations, data: keywordLocations } = useQuery(
    ["getKeywordLocations", { keyword }],
    async () => {
      const res: MainDbReturnTypes.KeywordLocations = await campaignClient.call(
        "getKeywordLocations",
        {
          keywordKey: keyword.keywordKey,
        }
      );
      return res.keywordLocations;
    },
    { enabled: keyword.keywordKey ? true : false }
  );


  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }



  const data = useMemo(() => {


    const getLegalName = (
      firstName: string | undefined | null,
      lastName: string | undefined | null
    ) => {
      if (firstName && lastName) {
        return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
      } else if (firstName) {
        return capitalizeFirstLetter(firstName);
      } else return "N/A";
    };
    if (prizeWinners && prizeWinners.length > 0 && prizes && keywordLocations && grandPrizeDraws) {

      return prizeWinners
        .map((rp) => {
          const filterGrandPrizeDraws = grandPrizeDraws.filter(
            (d) => d.grandPrizeDrawKey === rp.grandPrizeDrawKey
          );
          const participant = rp.participant;

          const expiryDays = keyword.dynamicSettings?.claimExpiry ? +keyword.dynamicSettings.claimExpiry : 10
          const expireTime = dayjs(rp.creationTime).add(expiryDays, "day").format("YYYY/MM/DD")
          const newParticipant = {
            firstName: participant.firstName || "",
            lastName: participant.lastName || "",
            language: participant.preferredLanguage || "en",
            email: participant.email || "",
            phone: participant.phone || "",
            // city: participant.city,
            // province: participant.province,
            prizeWinTime: new Date(rp.creationTime),
            prizeClaimTime: rp.declarationAndRelease?.creationTime
              ? new Date(rp.declarationAndRelease?.creationTime)
              : "",
            fulfillTime: rp.declarationAndRelease
              ? new Date(rp.declarationAndRelease.creationTime)
              : "NA",
            updateTime: rp.updateTime ? new Date(rp.updateTime) : "NA",
            status: rp.declarationAndRelease
              ? "Claimed"
              : dayjs().isAfter(dayjs(expireTime))
                ? "Expired"
                : "Pending Claim",
            prize: prizes[rp.prizeKey] ? prizes[rp.prizeKey][0].prizeName : "NA",
            sessionKey: participant.sessionKey,
            prizeWinnerKey: rp.prizeWinnerKey,
            keyword: keyword.word,
            stores: keywordLocations.length
              ? rp.participant.tags
                .filter((t) => t.includes("location"))
                .map(
                  (l) =>
                    keywordLocations.find(
                      (p) => p.keywordLocationKey === l.replace("location:", "")
                    )?.name
                )
                .filter((l) => l)
                .join(",")
              : "",
            winnerType: rp.parentPrizeWinnerKey ? "Guest" : "Primary",
            expirationDate: dayjs(expireTime).format("MM/DD/YYYY"),
            fulfilmentPartner: keyword.shipping?.email || "N/A",
            submittedToFullfillment: keyword.shipping?.notificationTime
              ? dayjs().isAfter(keyword.shipping.notificationTime)
              : false,
            invitedBy: rp.parentPrizeWinnerKey ? prizeWinners.find(w => w.prizeWinnerKey == rp.parentPrizeWinnerKey)?.participant.email : "",

            declaration: {
              firstName: rp.declarationAndRelease?.firstName || "",
              lastName: rp.declarationAndRelease?.lastName || "",
              email: rp.declarationAndRelease?.email || "",
              legalName:
                rp.declarationAndRelease ?
                  getLegalName(
                    rp.declarationAndRelease.firstName,
                    rp.declarationAndRelease?.lastName
                  ) : "",
              address: rp.declarationAndRelease?.address || "",
              address2: rp.declarationAndRelease?.address2 || "",
              city: rp.declarationAndRelease?.city || "",
              province: rp.declarationAndRelease?.province || "",
              postal: rp.declarationAndRelease?.postal || "",
              phone: rp.declarationAndRelease?.phone || "",
              claimedTime: rp.declarationAndRelease?.creationTime || "",
              signature: rp.declarationAndRelease?.digitalSignatureUrl || "",
            },
          };

          if (user.profile === "shipping" && storeKeyword.keyword.shipping.email) {
            const pickupStores = keywordLocations
              .map((k) => {
                if (k.dynamicSettings && k.dynamicSettings.pickupInStore) {
                  return k.name;
                }
              })
              .filter((v) => v);

            if (pickupStores.length <= 0) {
              return newParticipant;
            } else {
              const filterGrandPrizeDraws = grandPrizeDraws.filter(
                (d) => d.grandPrizeDrawKey === rp.grandPrizeDrawKey
              );

              const prizeDrawTags = filterGrandPrizeDraws[0].parameters.allTags || [];

              const location = getMatchLocation({
                participantTags: rp.participant.tags,
                prizeDrawTags,
                keywordLocations,
              });

              if (pickupStores.indexOf(location?.name) < 0) {
                return newParticipant;
              }
            }
          }

          if (user.profile !== "shipping") {
            return newParticipant;
          }
        })
        .filter((v) => v);
    }
  }, [prizeWinners, prizes, keywordLocations, keyword, grandPrizeDraws, storeKeyword.keyword.shipping.email, user.profile]);

  if (isLoadingPrizeWinners || isLoadingPrizes || isLoadingLocations || isLoadingGrandPrizeDraw) {
    return <PageLoader />;
  }
  return (
    <>
      {keyword.keywordKey ? <FilteredWinners winnersData={data ? data : []} prizeCount={keyword.dynamicSettings.prizeCount || "0"} /> : <SearchKeyword />}
    </>
  );
}

export default Winners;
